import * as React from "react";
import BgLogoSVG from "../components/svgs/BgLogoSVG";
import "../styles/global.css";
import "../styles/view-certificate.css";

export default function ViewCertificatePage() {
    const [src, setSrc] = React.useState("");
    React.useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const fileName = urlParams.get('name');

        if(fileName) {
            setSrc("../certificates/" + fileName);
        }
    }, []);
    
    return (
      <main id="view-certificate">
        <a className="bgLogo" href="../">
            <BgLogoSVG />
        </a>
        <div id="certificate-wrapper">
            <img
                alt="certificate"
                src={src}
                className="certificate"
            />
        </div>
      </main>
    )
  };
  
  export const Head = () => <title>Brodie Griggs</title>;
  